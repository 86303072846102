<template>
  <div class="content-filters mb-4">
    <div class="content-filter-date">
      <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
        min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="dates" outlined chips clearable class="global-text-field global-text-field-date"
            label="Seleccionar rango de fechas" placeholder="Seleccionar rango de fechas..."
            color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)" v-bind="attrs"
            v-on="on" readonly>
            <template slot="append">
              <v-icon @click="menu = true">mdi-calendar-blank</v-icon>
            </template>
          </v-text-field>
        </template>
        <v-date-picker v-model="dates" :max="sMaxDate" range no-title locale="es">
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false">
            Cancelar
          </v-btn>
          <v-btn :disabled="!bAddDates" text color="primary" @click="$refs.menu.save(), save()">
            <span :class="bAddDates ? 'color-yellow-global' : ''">Guardar</span>
          </v-btn>
        </v-date-picker>
      </v-menu>
    </div>
    <v-spacer></v-spacer>
    <div class="content-filter-menu-btn">
      <v-menu attach bottom left min-width="10" :close-on-content-click="false" offset-y rounded="lg">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" class="btn-filter-menu global-btn-neutral">
            <v-icon> mdi-tune-vertical </v-icon>
          </v-btn>
        </template>
        <v-card class="content-filter-menu">
          <div class="pa-5">
            <v-row>
              <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                <p class="title-filter">Transacción</p>
                <div class="content-major-minor">
                  <v-checkbox v-for="(item, index) in itemTransition" :key="index" v-model="sTransition" label=""
                    :value="item.value" class="global-checkbox" color="var(--primary-color-btn-primary)">
                    <template v-slot:label>
                      <span class="text-checkbox">{{ item.text }}</span>
                    </template>
                  </v-checkbox>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                <p class="title-filter">Cantidad procesada</p>
                <div class="content-major-minor">
                  <v-checkbox v-for="(item, index) in itemProcessedQuantity" :key="index" v-model="sProcessedQuantity"
                    :value="item.value" label="r" class="global-checkbox" color="var(--primary-color-btn-primary)">
                    <template v-slot:label>
                      <span class="text-checkbox">{{ item.text }}</span>
                    </template>
                  </v-checkbox>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-menu>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeSave: false,
      sSearch: "",
      dates: [],
      sMaxDate: new Date().toISOString().slice(0, 10),
      bAddDates: false,
      menu: false,
      sAlphabetic: "",
      itemAlphabetic: [
        {
          text: "De la A a la Z",
          value: "asc",
        },
        {
          text: "De la Z a la A",
          value: "desc",
        },
      ],
      sTransition: "",
      itemTransition: [
        {
          text: "Entrada",
          value: "in",
        },
        {
          text: "Salida",
          value: "out",
        },
      ],
      sProcessedQuantity: "",
      itemProcessedQuantity: [
        { text: "Mayor a menor", value: "desc" },
        {
          text: "Menor a mayor",
          value: "asc",
        },
      ],
    };
  },
  beforeMount() {
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
      if (this.dates.length > 1) {
        let fecha_inicial = this.dates[0];
        let fecha_final = this.dates[1];
        if (Date.parse(fecha_inicial) > Date.parse(fecha_final)) {
          let lastDate = this.dates[1];
          this.dates = [];
          this.dates[0] = lastDate;
        } else {
          this.$emit("setDate", {
            tStart: fecha_inicial,
            tEnd: fecha_final,
          });
        }
      }
    },
  },
  computed: {
    dateRangeText() {
      if (this.dates !== null) {
        return this.dates.join(' ~ ')

      } else {
        return []
      }
    },
  },
  watch: {
    sSearch() {
      this.$emit("setSearch", this.sSearch);
    },
    dates() {
      if (this.dates !== null) {
        if (this.dates.length > 1) {
          this.bAddDates = true;
          let fecha_inicial = this.dates[0];
          let fecha_final = this.dates[1];
          if (Date.parse(fecha_inicial) > Date.parse(fecha_final)) {
            let lastDate = this.dates[1];
            this.dates = [];
            this.dates[0] = lastDate;
          }
        } else {
          this.bAddDates = false;
        }
      } else {
        this.bAddDates = false;
        this.$emit("setDate", {
          tStart: null,
          tEnd: null,
        });
      }
    },
    sAlphabetic() { },
    sTransition() {
      this.$store.commit("setEntries", this.sTransition);
    },
    sProcessedQuantity() {
      this.$store.commit("setAZ", this.sProcessedQuantity);
    },
  },
};
</script>
<style scoped>
.p-text-title {
  color: var(--primary-color-color-title-information);
  font-family: "pop-Bold";
  font-size: 14px;
  letter-spacing: 4.8px;
  opacity: 1;
}

.title-filter {
  font-size: 14px;
  font-family: "pop-Bold";
}

.text-checkbox {
  font-size: 14px;
  font-family: "pop-Light";
}

.content-filters {
  display: flex;
  width: 100%;
}

.content-filter-show-for {
  width: 443px;
  margin-right: 15px;
}

.content-filter-date {
  width: 443px;
}

.content-filter-date .v-text-field {
  padding-top: 0px !important;
}

.content-filter-menu-btn {
  width: 50%;
  justify-content: end;
  display: flex;
}

.content-filter-menu {
  background-color: var(--primary-color-menu-filter) !important;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  overflow: hidden;
  border-radius: 10px !important;
  border: var(--primary-color-border-menu-profile) solid 1px;
}

.btn-filter-menu {
  width: 40px;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-filters {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }

  .content-filter-show-for {
    width: 100%;
    margin-right: 0px;
  }

  .content-filter-date {
    width: 100%;
  }

  .content-filter-menu-btn {
    width: 100%;
  }

  .btn-filter-menu {
    width: 100%;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .content-filter-show-for {
    width: 443px;
    margin-right: 15px;
  }

  .content-filter-date {
    width: 443px;
  }

  .content-filter-menu-btn {
    width: 15%;
    justify-content: end;
    display: flex;
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }

  .content-filter-show-for {
    width: 443px;
    margin-right: 15px;
  }

  .content-filter-date {
    width: 443px;
  }

  .content-filter-menu-btn {
    width: 45%;
    justify-content: end;
    display: flex;
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>